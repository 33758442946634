import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby";
import { Link as SmoothScroll } from 'react-scroll'

import './scss/index.scss'
import logoPoziom from "../../images/logo-poziom.svg";
import logo from "../../images/logo-wide.svg";
 
const Navigation = ({ menu, metadata, homepage }) => {
    return (
        <>
                <div className="navbar-main">
                    <ul className="navbar-nav align-items-center">
                        {menu.items.map((item, i) => (
                            <li className="nav-item" key={i}>
                                {homepage ?
                                    <SmoothScroll className="nav-link px-3" to={homepage ? item.url : `/${item.url}`} smooth={true} offset={-55} duration={500} >
                                        {item.title}
                                    </SmoothScroll>
                                :
                                    <Link className="nav-link px-3" to={homepage ? item.url : `/${item.url}`}>
                                        {item.title}
                                    </Link>
                                }
                            </li>
                        ))}
                        <Link to='/pytaj-o-co-chcesz/' className="btn btn-secondary btn-sm navbar-contact d-block d-lg-none mx-auto mt-4 mb-3">Pytaj o co chcesz</Link>
                    </ul>
                </div>
                <div className="navbar-language d-none d-lg-block">
                    <span className="navbar-text">Wybierz język</span>
                    <ul id="language-menu" className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" title="PL" href="#top">
                                <span className="wpml-ls-native" lang="pl">PL</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <Link className="navbar-brand d-flex justify-content-center d-lg-none" to="/" >
                    <img className="navbar-logo d-none d-lg-block" src={logoPoziom} alt={metadata.title}/>
                    <img className="navbar-logo d-block d-lg-none" src={logo} alt={metadata.title}/>
                </Link>
        </>
    )
}

Navigation.propTypes = {
    menu: PropTypes.object,
    metadata: PropTypes.object
}

export default Navigation;