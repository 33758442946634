import React, {useEffect, useState} from "react"
import { Link } from 'gatsby'
import gsap from 'gsap';
import 'bootstrap';
import Navigation from '../navigation/Navigation';
import './scss/index.scss';
import logo from '../../images/logo-wide.svg';

export default function Header({homepage, post}) {
    const [scrollDown, setScrollDown] = useState(true)

    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY < 100;
            if (scrollCheck !== scrollDown) {
                setScrollDown(scrollCheck)
            }
        })

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.equipment-buttons',
                start:"bottom 80px",
                toggleActions:"play none none reset"
            }
        });
        tl.to('.container > .navbar-contact', {duration: 0.35, opacity:1})
    })

    const site = {
        siteMetadata: {
            title: 'Szczurze wzgórze'
        }
    }

    const menu = {
        "items": [
            {
                "url": "#warunki",
                "title": "Warunki i wyposażenie"
            },
            {
                "url": "#aktualnosci",
                "title": "Aktualności"
            },
            {
                "url": "#wnetrza",
                "title": "Wnętrza i otoczenie"
            },
            {
                "url": "#atrakcje",
                "title": "Atrakcje"
            },
            {
                "url": "#lokalizacja",
                "title": "Lokalizacja"
            }
        ]
    }

    const lang = {
        "items": [
            {
                "url": "#pl",
                "title": "Polski"
            },
            {
                "url": "#en",
                "title": "English"
            }
        ]
    }

    return (
        <header className={`header ${scrollDown ? "hidden" : "white"} ${homepage ? 'homepage' : ''} ${post ? 'post' : ''}`}>
            <nav id="navbar-main" className={`navbar navbar-expand-lg fixed-top`}>
                <div className="container justify-content-between align-items-start align-items-center">
                    <div className="navbar-language d-block d-lg-none">
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarLang" aria-controls="navbarLang" aria-expanded="false" aria-label="Toggle language" lang="pl">PL</button>
                        <div className="collapse navbar-collapse" id="navbarLang">
                            <ul className="navbar-nav language-menu">
                            {lang.items.map((item, i) => {
                                return (
                                    <li key={i} className="nav-item">
                                        <a className="nav-link wpml-ls-native" title="PL" href="#top">
                                            {item.title}
                                        </a>
                                    </li>
                                )
                            })}
                            </ul>
                            <Link className="navbar-brand" to="/">
                                <img className="navbar-logo d-block d-lg-none mb-3" src={logo} alt={site.siteMetadata.title}/>
                            </Link>
                        </div>
                    </div>
                    <Link className="navbar-brand" to="/">
                        <img className={`navbar-logo ${scrollDown && 'navbar-logo--white'} d-none d-lg-block`} src={logo} alt={site.siteMetadata.title}/>
                        <img className={`navbar-logo ${scrollDown && 'navbar-logo--white'} d-block d-lg-none`} src={logo} alt={site.siteMetadata.title}/>
                    </Link>
                    <button className="navbar-toggler collapsed" id="collapseButton" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <Navigation menu={menu} metadata={site.siteMetadata} homepage={homepage} />
                    </div>
                    <Link to='/pytaj-o-co-chcesz/' className="btn btn-secondary btn-sm navbar-contact d-none d-lg-block">Pytaj o co chcesz</Link>
                </div>
            </nav>
        </header>
    )
}