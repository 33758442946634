import { useStaticQuery, graphql } from "gatsby"

export const useFooterData = () => {
    const { frontPagePlaces, newsPosts, info } = useStaticQuery(
        graphql`
            {
                frontPagePlaces: allWordpressWpPlaces {
                    edges {
                      node {
                        title
                        link
                      }
                    }
                }
                newsPosts: allWordpressPost {
                    edges {
                        node {
                            link
                            title
                        }
                    }
                }
                info: wordpressAcfOptions {
                    options {
                        phone
                        address
                        description
                        email
                        account
                    }
                }
            }
    `)
    return {places: frontPagePlaces, news: newsPosts, info: info }
}