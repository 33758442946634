import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'gatsby';
import { Element } from 'react-scroll'

import Section from "../section/Section"
import Contact from '../contact/Contact';
import Maps from "../maps/Maps";
import { useFooterData } from "./FooterData"
import Logo from '../../images/logo-idee.svg';
import './scss/index.scss';

const moreIcon = <svg xmlns="http://www.w3.org/2000/svg" width="12.25" height="12.25" viewBox="0 0 12.25 12.25"><path id="Path_628" data-name="Path 628" d="M11.375-6.562a.841.841,0,0,1,.615.26.841.841,0,0,1,.26.615v.875a.841.841,0,0,1-.26.615.841.841,0,0,1-.615.26H7.437V0a.841.841,0,0,1-.26.615.841.841,0,0,1-.615.26H5.687a.841.841,0,0,1-.615-.26A.841.841,0,0,1,4.812,0V-3.937H.875A.841.841,0,0,1,.26-4.2.841.841,0,0,1,0-4.812v-.875A.841.841,0,0,1,.26-6.3a.841.841,0,0,1,.615-.26H4.812V-10.5a.841.841,0,0,1,.26-.615.841.841,0,0,1,.615-.26h.875a.841.841,0,0,1,.615.26.841.841,0,0,1,.26.615v3.937Z" transform="translate(0 11.375)"/></svg>

const Footer = () => {
    const footerData = useFooterData()
    const infoData = footerData.info.options
    
    let newsDataSliced = footerData.news.edges.slice(0,4)
    let placesDataSliced = footerData.places.edges.slice(0,4)

    return (
        <footer className="footer">
            <Element name="#lokalizacja">
                <Section id="lokalizacja" header="Jak dojechać" hideHeader fullwidth>
                    <Maps/>
                </Section>
            </Element>
            <Section className="footer-contact py-5" header="Dane kontaktowe" hideHeader>
                <div className="row">
                    <div className="col">
                        <Contact/>
                    </div>
                </div>
            </Section>
            <Section className="footer-links pt-lg-0 pt-lg-6 pt-lg-4 pb-5 bg-light">
                <div className="row">
                    <div className="col-12 col-lg-4 mb-6 mb-lg-0">
                        <h3 className="footer-header">Aktualności</h3>
                        <ul className="footer-link-container">
                            {newsDataSliced.map((newsItem, i) => {
                                return <li key={i} className="footer-link"><Link to={'/aktualnosci/' + newsItem.node.link}>{newsItem.node.title}</Link></li>
                            })}
                        </ul>
                        <Link to='/aktualnosci/' className="footer-link more">Więcej aktualności {moreIcon}</Link>
                    </div>
                    <div className="col-12 col-lg-4 mb-6 mb-lg-0">
                        <h3 className="footer-header">Atrakcje</h3>
                        <ul className="footer-link-container">
                            {placesDataSliced.map((placeItem, i) => {
                                return <li key={i} className="footer-link"><Link to={'/' + placeItem.node.link}>{placeItem.node.title}</Link></li>
                            })}
                        </ul>
                        <Link to='/atrakcje/' className="footer-link more">Więcej atrakcji{moreIcon}</Link>
                    </div>
                    <div className="col-12 col-lg-4">
                        <h3 className="footer-header">Informacje o miejscu</h3>
                        <div className="footer-text">{ ReactHtmlParser(infoData.description) }</div>
                        <Link to='#' className="footer-link more d-none">Więcej o miejscu {moreIcon} </Link>
                    </div>
                </div>
            </Section>
            <Section header="Prawa autorskie" hideHeader className="footer-copyright pt-5 pb-4">
                <div className="row">
                    <div className="col pt-2">
                        <div className="w-100 border-bottom pb-2 d-flex align-items-center justify-content-sm-start justify-content-between">
                            <div className="d-sm-block d-flex flex-column">
                                <strong className="me-1">Rezerwacje:</strong>
                                <a href={`tel:${infoData.phone.replace(/\s/g, '')}`}>{infoData.phone}</a>
                            </div>
                            <Link to="/pytaj-o-co-chcesz/" className="btn btn-outline-primary btn-sm">Kontakt
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.998" height="14.003" viewBox="0 0 13.998 14.003">
                                    <path id="Path_642" data-name="Path 642" d="M-.984-12.162-13.658-4.851A.657.657,0,0,0-13.6-3.67l2.907,1.22,7.856-6.923a.164.164,0,0,1,.235.227L-9.188-1.121v2.2a.656.656,0,0,0,1.162.432L-6.289-.6-2.882.826a.658.658,0,0,0,.9-.5L-.011-11.484A.656.656,0,0,0-.984-12.162Z" transform="translate(14 12.251)"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row py-1 d-flex justify-content-between">
                    <div className="col-12 col-lg-9 mb-5 mb-lg-0">© 2020-2022 Kinga Jóźwiak - Szczur Wynajem Nieruchomości</div>
                    <div className="col-12 col-lg-3 mb-3 mb-lg-0 text-lg-right">Projekt i wdrożenie: <a href="https://ideeconcept.pl/" target="_blank" rel="noreferrer"><img src={Logo} alt="Logo Ideeconcept"/></a></div>
                </div>
            </Section>
        </footer>
    )
}

export default Footer;