import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import './scss/index.scss';
import { useFooterData } from "../footer/FooterData"
import logo from '../../images/logo-wide.svg';

const Contact = () => {
    const footerData = useFooterData()
    const infoData = footerData.info.options

    return (
        <address className="contact d-flex flex-column justify-content-center align-items-center">
            <img className="contact-logo" src={logo} alt="Logo Szczurze Wzgórze"/>
            <div className="contact-address d-flex flex-column justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="14.25" height="19" viewBox="0 0 14.25 19">
                    <path id="Path_710" data-name="Path 710" d="M-.742,2A.852.852,0,0,0,0,2.375.852.852,0,0,0,.742,2L3.228-1.559q1.855-2.672,2.449-3.6A10.451,10.451,0,0,0,6.828-7.4a6.755,6.755,0,0,0,.3-2.1,6.877,6.877,0,0,0-.965-3.562,7.261,7.261,0,0,0-2.6-2.6A6.877,6.877,0,0,0,0-16.625a6.877,6.877,0,0,0-3.562.965,7.261,7.261,0,0,0-2.6,2.6A6.877,6.877,0,0,0-7.125-9.5a6.755,6.755,0,0,0,.3,2.1,10.451,10.451,0,0,0,1.15,2.245q.594.928,2.449,3.6Q-1.707.594-.742,2ZM0-6.531A2.859,2.859,0,0,1-2.1-7.4a2.859,2.859,0,0,1-.872-2.1A2.859,2.859,0,0,1-2.1-11.6,2.859,2.859,0,0,1,0-12.469a2.859,2.859,0,0,1,2.1.872,2.859,2.859,0,0,1,.872,2.1A2.859,2.859,0,0,1,2.1-7.4,2.859,2.859,0,0,1,0-6.531Z" transform="translate(7.125 16.625)" fill="#a70040"/>
                </svg>
                { ReactHtmlParser(infoData.address) }
            </div>
            <div className="contact-bank">
                rachunek do wpłat:<br/>
                {infoData.account}<br/>
                prosimy o umieszczanie w tytule nr rezerwacji
            </div>
            <div className="contact-mail d-flex flex-column justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14.25" viewBox="0 0 19 14.25">
                    <path id="Path_709" data-name="Path 709" d="M9.129-9.537a.183.183,0,0,1,.241-.037.241.241,0,0,1,.13.223v7.57A1.718,1.718,0,0,1,8.98-.52,1.718,1.718,0,0,1,7.719,0H-7.719A1.718,1.718,0,0,1-8.98-.52,1.718,1.718,0,0,1-9.5-1.781v-7.57a.2.2,0,0,1,.13-.2.24.24,0,0,1,.241.019q1.225.928,5.715,4.193l.371.334a14.762,14.762,0,0,0,1.41.965A3.476,3.476,0,0,0,0-3.562a3.294,3.294,0,0,0,1.633-.52,10.362,10.362,0,0,0,1.41-.965l.371-.3Q7.793-8.5,9.129-9.537ZM0-4.75A2.369,2.369,0,0,1-1.188-5.2a8.843,8.843,0,0,1-1.15-.816l-.371-.26q-4.564-3.34-6.2-4.6l-.26-.186a.846.846,0,0,1-.334-.705v-.705a1.718,1.718,0,0,1,.52-1.262,1.718,1.718,0,0,1,1.262-.52H7.719a1.718,1.718,0,0,1,1.262.52,1.718,1.718,0,0,1,.52,1.262v.705a.846.846,0,0,1-.334.705l-.186.148q-1.6,1.262-6.271,4.639l-.371.26a8.843,8.843,0,0,1-1.15.816A2.369,2.369,0,0,1,0-4.75Z" transform="translate(9.5 14.25)" fill="#a70040"/>
                </svg>
                <a href={`mailto:${infoData.email}`}>{infoData.email}</a>
            </div>
        </address>
    )
}

export default Contact;