import React from "react"
import PropTypes from "prop-types"

import Header from "./header/Header"
import Footer from "./footer/Footer"
import "./layout.scss"
import SEO from "./seo"

const Layout = ({ children, homepage, post }) => {
    return (
        <>
            <SEO/>
            <Header homepage={homepage} post={post}/>
                <div id="primary" className="content-area">
                    <main id="main" className="site-main" role="main">
                        {children}
                    </main>
                </div>
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    homepage: PropTypes.bool
}

export default Layout
