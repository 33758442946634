import React from "react"

import "./scss/index.scss"

const Places = ({ mapUrl }) => {
  return (
    <div className="maps">
      <iframe
        title="Jak dojechac"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5040.533261425999!2d15.655477!3d50.826225!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470edd7610188fb3%3A0x8f219deb795923bc!2sSzczurze%20Wzg%C3%B3rze!5e0!3m2!1spl!2sus!4v1670846280480!5m2!1spl!2sus"
        allowFullScreen
        loading="lazy"
      ></iframe>
    </div>
  )
}

export default Places
