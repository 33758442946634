import React from "react"
import PropTypes from "prop-types"

import "./scss/index.scss"

const Section = ({ header, hideHeader, fullwidth, className, id, children }) => {
    return (
        <section id={id} className={`section ${className ? className : ""}`}>
            <div className={fullwidth ? 'container-fluid' : 'container'}>
                <h2 className={hideHeader ? 'visually-hidden' : ''}>{header}</h2>
                    {children}
            </div>
        </section>
    )
}

Section.propTypes = {
    header: PropTypes.node.isRequired,
    hideHeader: PropTypes.bool,
    fullwidth: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.node.isRequired,
}

export default Section
