/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"

function SEO({ description, lang, meta, title }) {
  return (
    <Helmet>
      <title>{title || 'Cisza i natura na wyciągnięcie ręki'} | Szczurze Wzgórze</title>
        <script async src="https://badge.hotelstatic.com/embed.js" data-url="https://www.booking.com/hotel/pl/szczurze-wzgorze.html" data-size="80" data-position="inline" data-clickable="true"></script>
    </Helmet>
  )
}

export default SEO
